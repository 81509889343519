import { ThemeProvider } from '@mui/material/styles';
import App from 'next/app';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import configureStore from '../redux/store';
import theme from '~/src/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import getDomain from '~/src/lib/getDomain';
import actionsBusiness from '~/redux/business/actions';
import actionsApp from '~/redux/app/actions';
import messageService from '~/src/lib/req/message';
import isIterable from '~/src/lib/isIterable';
import config from '~/src/config';
import { useRouter } from 'next/router';
import * as storage from '~/src/lib/common/storage/browserStorage';
import * as analytics from '~/src/lib/analytics';
import Cookies from 'cookies';
import dynamic from 'next/dynamic';
import postNativeApp from '~/src/lib/postNativeApp';
import checkCookieQrcode from '~/src/lib/checkCookieQrcode';

import './css/styles.css';
import './css/mediaquery.css';
import './css/animation.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '~/src/styles/file-picker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import '~/public/css/slick-theme.css';
import '~/src/styles/stripe.css';

const Layout = dynamic(() => import('~/src/components/Layout'), {
    ssr: false,
});

const ModalChooseLang = dynamic(
    () => import('~/src/components/ModalChooseLang'),
    {
        ssr: false,
    },
);

const HandleLocaleRedirect = dynamic(
    () => import('~/src/components/HandleLocaleRedirect'),
    {
        ssr: false,
    },
);

setInterval(() => {
    checkCookieQrcode();
}, 10000);

/**
 * Object.entriesFrom() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!Object.fromEntries) {
    Object.fromEntries = function (entries) {
        if (!entries || !entries[Symbol.iterator]) {
            throw new Error(
                'Object.fromEntries() requires a single iterable argument',
            );
        }
        let obj = {};
        for (let [key, value] of entries) {
            obj[key] = value;
        }
        return obj;
    };
}

function MyApp({ Component, pageProps, store }) {
    const router = useRouter();

    setTimeout(() => {
        postNativeApp();
    }, 2000);

    useEffect(() => {
        const handleRouteChange = (url, { shallow }) => {
            analytics.logPageView(url);
            storage.setItem('referrer', router.asPath);
        };

        router.events.on('routeChangeStart', handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router]);

    const handleClick = (e) => {
        //console.log('addEventListener changeroute');
        //console.log(e);
        const { detail: path } = e;
        router.push(path);
    };

    useEffect(() => {
        window.addEventListener('changeroute', handleClick, true);
        return () => window.removeEventListener('changeroute', handleClick);
    });

    if (store && typeof document === 'object') {
        const state = store.getState();
        if (state) {
            const { business } = state;

            if (business && business.info && business.info.custom_css) {
                const customCss = document.createElement('style');
                customCss.innerText = business.info.custom_css;
                document.getElementsByTagName('head')[0].appendChild(customCss);
            }

            if (business && business.info && business.info.custom_css_url) {
                const head = document.head;
                const link = document.createElement('link');

                link.type = 'text/css';
                link.rel = 'stylesheet';
                link.href = business.info.custom_css_url;

                head.appendChild(link);
            }

            if (business && business.info && business.info.favicon) {
                const head = document.head;
                const link = document.createElement('link');

                link.type = 'image/png';
                link.rel = 'icon';
                link.href = `${config.specificAssetsUrl}/favicon/${business.info.favicon}`;

                head.appendChild(link);
            }
        }
    }

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <HandleLocaleRedirect />
                <Layout>
                    <ModalChooseLang />
                    <Component {...pageProps} />
                </Layout>
                <ToastContainer />
            </ThemeProvider>
        </Provider>
    );
}

//console.log('111111');

MyApp.getInitialProps = async (appContext) => {
    const appProps = await App.getInitialProps(appContext);
    appProps.revalidate = 60;

    let lang = appContext.ctx.locale;
    let url;

    const _getJsonUrl = (domain, lang) => {
        const u = `${config.jsonUrl}/${domain}-${lang}.json?time=${Date.now()}`;
        console.log(u);
        return u;
    };

    if (appContext.ctx && appContext.ctx.isServer === true) {
        //console.log('STEP1');
        //console.log(lang);
        //console.log(appContext.ctx.req.url);
        //console.log(appContext.ctx.req.headers.host);

        if (
            [
                '/authuser',
                '/from_app',
                '/error_business',
                '/qrcode',
                '/redirect',
            ].includes(appContext.ctx.req.url) === false
        ) {
            let data = false;
            let redirect = false;
            const domain = getDomain(appContext.ctx.req.headers.host);
            console.log(domain);

            // on recupere les langue du business
            const businessInfo = await messageService.get(
                `/business/info/${domain}`,
            );

            let foundJson = false;

            if (
                businessInfo &&
                typeof businessInfo?.data === 'object' &&
                isIterable(businessInfo?.data?.languages)
            ) {
                //console.log('businessInfo');
                //console.log(businessInfo);
                const match = businessInfo?.data?.languages.find(
                    (bl) => bl.iso2 === lang,
                );

                //console.log('DEBUG1');
                //console.log(match);

                if (match) {
                    url = _getJsonUrl(domain, lang);
                    const res = await fetch(url);
                    if (res.status === 200) {
                        data = await res.json();
                        foundJson = true;
                    }
                }
                if (foundJson === false) {
                    const match = businessInfo?.data?.languages.find(
                        (bl) => bl.is_default === 1,
                    );
                    //console.log('PPPPPPPPP');
                    //console.log(match);
                    if (match) {
                        lang = match.iso2;
                        url = _getJsonUrl(domain, lang);
                        const res = await fetch(url);
                        if (res.status === 200) {
                            data = await res.json();
                            foundJson = true;
                        }
                    }
                }
            }

            if (foundJson === false) {
                url = _getJsonUrl(domain, lang);
                const res = await fetch(url);
                //console.log(111);
                //console.log(res.status);
                //console.log('STEP2');

                if (res.status === 200) {
                    data = await res.json();
                } else {
                    lang = 'fr';
                    url = _getJsonUrl(domain, lang);
                    const res = await fetch(url);

                    if (res.status === 200) {
                        data = await res.json();
                    } else {
                        if (
                            config.client === 'netto' ||
                            config.client === 'regioneo'
                        ) {
                            lang = 'it';
                        } else {
                            lang = 'en';
                        }

                        url = _getJsonUrl(domain, lang);
                        const res = await fetch(url);

                        if (res.status === 200) {
                            data = await res.json();
                        }
                    }
                }
            }

            //console.log('STEP3');
            //console.log(data);
            //console.log(appContext.ctx);

            if (appContext.ctx.locale !== lang) {
                //console.log('REDIRECT 1111');
                //console.log(appContext.ctx.locale);
                //console.log(lang);
                //console.log(appContext.ctx.req.url);
                redirect = true;
                appContext.ctx.res
                    .writeHead(302, {
                        Location: `/${lang}`,
                    })
                    .end();
            }

            //console.log('STEP4');

            if (!data && redirect === false) {
                //console.log('REDIRECT 2222');
                redirect = true;
                appContext.ctx.res
                    .writeHead(302, {
                        Location: '/error_business',
                    })
                    .end();
            }

            //console.log('STEP5');

            //console.log(appContext.ctx.res);
            //appContext.ctx.res.cookie('cookieName',lang, { maxAge: 900000, httpOnly: true });

            if (data && redirect === false) {
                const cookies = new Cookies(
                    appContext.ctx.req,
                    appContext.ctx.res,
                );
                cookies.set('NEXT_LOCALE', lang, {
                    httpOnly: false, // true by default
                    maxAge: 30 * 24 * 600 * 600,
                    path: '/',
                });
                cookies.set('lang', lang, {
                    httpOnly: false, // true by default
                    maxAge: 30 * 24 * 600 * 600,
                    path: '/',
                });

                appContext.ctx.store.dispatch(
                    actionsBusiness.loadBusiness({
                        req: appContext.ctx.req,
                        data: data,
                        lang: lang,
                    }),
                );
                appContext.ctx.store.dispatch(actionsApp.setDomain(domain));
            }
            //console.log(data);
            //console.log('STEP6');

            /*
            const cookies = parseCookies(appContext.ctx.req);
            console.log(cookies);
            if(cookies.NEXT_LOCALE) {
                console.log(cookies.NEXT_LOCALE); 
            }
            */
        }
    }

    if (appContext.ctx && appContext.ctx.isServer === true) {
        //console.log(1111111);
        //console.log(appContext.router.query);
        //console.log(appContext.ctx.isServer);
        //console.log(appContext.ctx);

        appProps.pageProps.host = appContext.ctx.req.headers.host;
        //appProps.pageProps.res = appContext.ctx.res;

        if (
            appContext.router &&
            appContext.router.pathname &&
            appContext.router.pathname === '/order_takeaway'
        ) {
            if (appContext.router.query && appContext.router.query.session_id) {
                // page de confirmation stripe
            } else {
                let protocol = 'https';
                if (appContext.ctx.req.headers.host.includes('localhost')) {
                    protocol = 'http';
                }
                if (appContext.ctx.res) {
                    appContext.ctx.res.writeHead(301, {
                        Location: `${protocol}://${appContext.ctx.req.headers.host}`,
                    });
                    appContext.ctx.res.end();
                }
            }
        }

        //console.log(1111111);
        //console.log(appContext.router.pathname);

        //console.log(appContext.ctx.req.headers.host);
        // sitemap
        if (
            appContext.router &&
            appContext.router.pathname &&
            appContext.router.pathname === '/sitemap.xml'
        ) {
            const host = appContext.ctx.req.headers.host;
            const url = `/sitemap/${host}`;
            const result = await messageService.get(`/sitemap/${host}`);
            if (result && result.result === true && result.data) {
                appContext.ctx.res.statusCode = 200;
                appContext.ctx.res.setHeader('Content-Type', 'application/xml');
                //appContext.ctx.res.status(200).send(xml);
                appContext.ctx.res.write(result.data);
                appContext.ctx.res.end();
            }
        }
    }

    return { ...appProps };
};

export default configureStore(MyApp);
