export const getDomain = (host) => {
    //return 'twenty';

    let domain = 'biodegustation';

    //https://www.yibowlnoodle.com/fr/m/sur-place?qrcode=unay23

    //breguet

    if (host) {
        const hostSplit = host.split('.');

        //console.log(333333);
        //console.log(host);

        if (host === 'next-foound-netto-dev.vercel.app') {
            domain = 'businessnewenv';
        } else if (host === 'backpack-foound.herokuapp.com') {
            domain = 'biodegustation';
        } else if (host.includes('vercel')) {
            domain = 'cyrille';
        } else if (host === 'www.regioneo.com') {
            domain = 'regioneo';
        } else if (host === 'www.ristorantefreidour.it') {
            domain = 'freidour';
        } else if (host === 'microfermeracines.fr') {
            domain = 'microfermeracines';
        } else if (host === 'www.microfermeracines.fr') {
            domain = 'microfermeracines';
        } else if (host === 'ristorantefreidour.it') {
            domain = 'freidour';
        } else if (host === 'www.freidour.it') {
            domain = 'freidour';
        } else if (host === 'freidour.it') {
            domain = 'freidour';
        } else if (host === 'materrehappy81.com') {
            domain = 'materrehappy';
        } else if (host === 'www.materrehappy81.com') {
            domain = 'materrehappy';
        } else if (host === 'regioneo.com') {
            domain = 'regioneo';
        } else if (host === 'www.ata-paris.com') {
            domain = 'ataparis';
        } else if (host === 'ata-paris.com') {
            domain = 'ataparis';
        } else if (host === 'www.yibowlnoodle.com') {
            domain = 'yibowl';
        } else if (host === 'yibowlnoodle.com') {
            domain = 'yibowl';
        } else if (host === 'yionefood.com') {
            domain = 'yione';
        } else if (host === 'www.yionefood.com') {
            domain = 'yione';
        } else if (host === 'le-cru-de-paris.com') {
            domain = 'lecrudeparis';
        } else if (host === 'www.le-cru-de-paris.com') {
            domain = 'lecrudeparis';
        } else if (host === 'boutique.cueillettedubosc.fr') {
            domain = 'cueillettedubosc';
        } else if (host === 'baptiste.blanchett.go-4.art') {
            domain = 'goforart';
        } else if (host === 'www.menuchic.com') {
            domain = 'menuchic';
        } else if (host === 'menuchic.com') {
            domain = 'menuchic';
        } else if (host === 'next.local:3000') {
            domain = 'cyrille';
        } else if (host === 'elior.foound.com') {
            domain = 'eliorpreprod';
        } else if (host === 'heroku.foound.com') {
            domain = 'jardinviolette';
        } else if (host === '75006.fr') {
            domain = 'cyrille';
        } else if (host === 'breguet.elior.app') {
            domain = 'breguet';
        } else if (host === 'twenty.elior.app') {
            domain = 'twenty';
        } else if (host.includes('breguet.foound.be')) {
            domain = 'breguet';
        } else if (host === 'menusv3.netlify.com') {
            domain = 'cyrille';
        } else if (host.includes('pizzeria-restaurant-cucina.fr')) {
            domain = 'pizzeriarestaurantcucina';
        } else if (host.includes('restaurant-chez-jacques.fr')) {
            domain = 'restaurantchezjacques';
        } else if (host.includes('www.restaurant-chez-jacques.fr')) {
            domain = 'restaurantchezjacques';
        } else if (host === 'www.elior.app') {
            domain = 'www';
        } else if (host === 'elior.app') {
            domain = 'www';
        } else if (host.includes('elior.app')) {
            domain = hostSplit[0];
        } else if (host.includes('foound.es')) {
            domain = hostSplit[0];
        } else if (host.includes('menuchic.com')) {
            domain = hostSplit[0];
        } else if (host.includes('menuchic.net')) {
            domain = hostSplit[0];
        } else if (host.includes('foound.in')) {
            domain = hostSplit[0];
        } else if (host.includes('bienchoisir.com')) {
            domain = hostSplit[0];
        } else if (host.includes('regioneo.com')) {
            domain = hostSplit[0];
        } else if (host.includes('foound.me')) {
            domain = hostSplit[0];
        } else if (host.includes('foound.com')) {
            domain = hostSplit[0];
        } else if (host.includes('foound.net')) {
            domain = hostSplit[0];
        } else if (host.includes('go-4.art')) {
            domain = hostSplit[0];
        } else if (hostSplit.length === 1) {
            //domain = 'jardinviolette';
            // jardinviolette / cyrille / nylsa / mysensbox / eugenette / banh-mi / artmondo / biodegustation
        } else if (hostSplit.length === 2) {
            domain = hostSplit[0];
        } else if (hostSplit.length === 3) {
            domain = hostSplit[1].replace('-', '');
        }

        return domain;
    }

    return domain;
};

export default getDomain;
